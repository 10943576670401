<template>
    <div class="Awarp">
        <dashCard class="lvscBox9" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption" @changeScreen="changeScreen">
            <template slot="title">{{commonLangHandler('lvscBox9_switchgear','开关柜基础数据', getZEdata)}}</template>
            <template slot="aside"></template>
            <template slot="operate"></template>
            <a-descriptions bordered>
                <a-descriptions-item v-for="(item,idx) in columns" :key="idx" :label="item.title" :span="3">
                    <span v-if="!!item.value1">{{ detailInfo[item.value1] }}</span>
                    <span v-if="!!item.value2"> {{ detailInfo[item.value2] }}</span>
                </a-descriptions-item>
            </a-descriptions>
        </dashCard>
        <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false" width="100%" :dialog-style="{ top: '0px' }">
            <div style="height:calc(100vh)">
                <lvscBox9  :option="option" :isModal="true" :orginSearchObj="searchObj"/>
            </div>
        </a-modal>
    </div>
    </template>
    <script>
    export default {
        name: 'lvscBox9',
        components: {
            dashCard: () => import('@/component/dashCard.vue'),
        },
        props: {
            gridOption: {
                type: Object,
                default: function() {
                    return { w: 6, h: 6 }
                }
            },
            option: {
                type: Object,
                default: function() {
                    return {
                        visible: false
                    }
                }
            },
            isModal: {
                type: Boolean,
                default: function() {
                    return false
                }
            },
            orginSearchObj: {
                type: Object,
                default: function() {
                    return {}
                } 
            }
        },
        watch: {
            gridOption: {
                handler: function(newVal,oldVal) {
                    this.$nextTick(_=>{
                        this.getOperateWidth()
                    })
                },
                deep: true,
                immediate: true
            },
            "option.visible": {//全屏化时执行
                handler: function(newVal,oldVal) {
                    if(newVal) {
                        if(this.isModal) {
                            this.searchObj = this.orginSearchObj
                            this.getData()
                        }
                    }
                },
                deep: true,
                immediate: true
            },
        },
        data() {
            return {
                operateWidth: 64, //操作列宽度
                isLoading: false,//数据加载中
                searchObj: {},
                columns: [
                    { title: '型号', value1: 'value1'},
                    { title: '额定工作电压', value1: 'value2',value2: 'value3'},
                    { title: '额定冲击耐受电压_Uimp', value1: 'value4',value2: 'value5'},
                    { title: '过电压等级', value1: 'value6'},
                    { title: '额定频率', value1: 'value7',value2: 'value8'},
                    { title: '污染等级', value1: 'value9'},
                    { title: '母线额定电流_Ie', value1: 'value10',value2: 'value11'},
                    { title: '母线额定峰值耐受电流_Ipk', value1: 'value12',value2: 'value13'},
                    { title: '母线短时耐受电流_Icw', value1: 'value14',value2: 'value15'},
                    { title: '电弧等级', value1: 'value16'},
                    { title: '适用的标准', value1: 'value17'},
                    { title: '制造年月', value1: 'value18'},
                    { title: '出厂编号', value1: 'value19'},
                ],
                detailInfo: {},
                }
        },
        computed: {},
        methods: {
            // 全屏切换
            changeScreen() {
                this.option.visible = !this.isModal
                if(!this.option.visible) {
                    this.getData()
                }
            },
            // 右侧操作列宽度
            getOperateWidth () {
                if(!this.$el.querySelector) {
                    return
                }
                var dom = this.$el.querySelector(".card-head-operate")
                if(!!dom) {
                    var width = dom.clientWidth
                    this.operateWidth = width > 0 ? width + 12 : width  
                }
            },
            // 数字转为千位分隔符表示
            toThousandsSeparator: function(value) {
                if(!value) return 0
                // 获取整数部分
                const intPart = Math.trunc(value)
                // 整数部分处理，增加,
                const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
                // 预定义小数部分
                let floatPart = ''
                // 将数值截取为小数部分和整数部分
                const valueArray = value.toString().split('.')
                if (valueArray.length === 2) { // 有小数部分
                    floatPart = valueArray[1].toString() // 取得小数部分
                    return intPartFormat + '.' + floatPart
                }
                return intPartFormat + floatPart
            },

            // 获取数据
            getData() {
                this.isLoading = true
                this.detailInfo = {
                    value1:"MNS",
                    value2:"690",
                    value3:"V",
                    value4:"8",
                    value5:"kV",
                    value6:"III",
                    value7:"50",
                    value8:"Hz",
                    value9:"3",
                    value10:"2500",
                    value11:"A",
                    value12:"264",
                    value13:"kA",
                    value14:"100",
                    value15:"kA,3s",
                    value16:"C",
                    value17:"GBxxx",
                    value18:"2023-01",
                    value19:"QS211201027680",
                }
                this.isLoading = false
            },
        },
        mounted() {
            this.getOperateWidth()
            if(!this.option.visible) {
                this.getData()
            }
        }
    }
    </script>
    
<style lang="less" scoped>
.lvscBox9 {
    /deep/.ant-descriptions-bordered {
        .ant-descriptions-item-label {
            background: transparent;
            width: 362px;
        }
        .ant-descriptions-item-content {
            background: #F5F5F5;
            display: flex;
            justify-content: space-between;
        }
    }
}
</style>